import {SUMMARY_THEMES} from '../const';

const getHeaderRow = summaryTheme => {
  const categoryHeaderCell = {type: 'header', text: 'Catégorie', className: 'header-cell'};
  return {
    rowId: 'header',
    height: 40,
    cells: [
      {type: 'header', text: 'Mois', className: 'header-cell'},
      ...(summaryTheme !== SUMMARY_THEMES.ca ? [categoryHeaderCell] : []),
      {type: 'header', text: 'Libellé', className: 'header-cell'},
      {type: 'header', text: 'Montant', className: 'header-cell'},
      {type: 'header', text: ' ', className: 'header-cell'}
    ]
  };
};

const SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Mois', className: 'header-cell'},
    {type: 'header', text: 'Montant', className: 'header-cell'},
    {type: 'header', text: ' ', className: 'header-cell'}
  ]
};

const SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES = {
  date: 'date',
  category: 'category',
  amount: 'amount',
  label: 'label',
  delete: 'delete'
};

const SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS_NAMES = {
  date: 'date',
  amount: 'amount',
  delete: 'delete'
};

const getSummariesShareDataColumns = (customWidths, dialogWidth, summaryTheme) => {
  const defaultColumnWidth = 150;
  const deleteColumnWidth = 50;
  const numberOfColumns = summaryTheme !== SUMMARY_THEMES.ca ? 3 : 2;
  const widthAlreadyTaken = defaultColumnWidth * numberOfColumns + deleteColumnWidth;
  const widthLeft = dialogWidth - widthAlreadyTaken;
  const labelColumnWidth = widthLeft < 150 ? 150 : widthLeft;

  const categoryColumn = {
    resizable: true,
    columnId: SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.category,
    width: customWidths.find(col => col.id === SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.category)?.width || defaultColumnWidth
  };

  const columns = [
    {
      resizable: true,
      columnId: SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.date,
      width: customWidths.find(col => col.id === SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.date)?.width || defaultColumnWidth
    },
    ...(summaryTheme !== SUMMARY_THEMES.ca ? [categoryColumn] : []),
    {
      resizable: true,
      columnId: SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.label,
      width: customWidths.find(col => col.id === SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.label)?.width || labelColumnWidth
    },
    {
      resizable: true,
      columnId: SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.amount,
      width: customWidths.find(col => col.id === SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.amount)?.width || defaultColumnWidth
    },
    {
      columnId: SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.delete,
      width: deleteColumnWidth
    }
  ];

  return columns;
};

const getSummariesShareAnonymizedDataColumns = () => {
  const defaultColumnWidth = 250;
  const deleteColumnWidth = 75;

  return [
    {
      columnId: SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS_NAMES.date,
      width: defaultColumnWidth
    },

    {
      columnId: SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS_NAMES.amount,
      width: defaultColumnWidth
    },
    {
      columnId: SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS_NAMES.delete,
      width: deleteColumnWidth
    }
  ];
};

const groupEntriesAmountsByDate = array => {
  const groupedMap = array.reduce((acc, item) => {
    const date = new Date(item.Mois);
    const dateKey = date.toLocaleDateString('fr-FR');

    if (acc.has(dateKey)) {
      const currentAmount = acc.get(dateKey).Montant;
      const newAmount = item.Montant + currentAmount;
      acc.set(dateKey, {
        ...item,
        Montant: newAmount
      });
    } else {
      acc.set(dateKey, {
        ...item,
        Montant: item.Montant
      });
    }

    return acc;
  }, new Map());

  return Array.from(groupedMap.values());
};

export {getHeaderRow, SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS, getSummariesShareDataColumns, getSummariesShareAnonymizedDataColumns, groupEntriesAmountsByDate};
