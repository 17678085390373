import {forwardRef, useState} from 'react';

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {Menu, useTheme} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {models} from 'powerbi-client';
import {bool, element, func, node, object, oneOf, oneOfType} from 'prop-types';

import {BREAKPOINTS} from '../../const';
import useBreakpoint from '../../hooks/dom/useBreakpoint';
import useChatbot from '../../hooks/providers/useChatbot';
import useReport from '../../hooks/providers/useReport';
import {csvToMarkdown, groupVisualsByPage} from '../../utils';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChatbotShareDataMenu = ({isOpen, onClose, anchorEl}) => {
  const [isLoadingDataFromVisual, setIsLoadingDataFromVisual] = useState(false);
  const {selectedReport} = useReport();
  const {sendableReportVisuals, sendAccountingFile, currentThreadId, setIsSendingMessage, setSendFileInProgress, setIsNavigatingToOtherReportPageToExportData} = useChatbot();

  const theme = useTheme();
  const currentBreakpoint = useBreakpoint();
  const isExtraSmallScreen = currentBreakpoint === BREAKPOINTS.xs;

  const onSelect = async visual => {
    setIsLoadingDataFromVisual(true);
    setIsSendingMessage(true);
    setSendFileInProgress(true);
    try {
      let exportedData;
      onClose();
      if (visual.page.isActive) {
        exportedData = await visual.exportData(models.ExportDataType.Summarized);
      } else {
        setIsNavigatingToOtherReportPageToExportData(true);
        const pageBefore = await selectedReport.getActivePage();
        const pageToNavigate = visual.page;
        await selectedReport.setPage(pageToNavigate.name);
        exportedData = await visual.exportData(models.ExportDataType.Summarized);
        await selectedReport.setPage(pageBefore.name);
        setIsNavigatingToOtherReportPageToExportData(false);
      }
      const formattedData = csvToMarkdown(exportedData.data);
      const base64Data = btoa(formattedData);
      const filename = visual.title;
      await sendAccountingFile(base64Data, filename, currentThreadId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setIsNavigatingToOtherReportPageToExportData(false);
    } finally {
      setIsLoadingDataFromVisual(false);
      setIsSendingMessage(false);
      setSendFileInProgress(false);
    }
  };

  const groupedVisualsByPage = groupVisualsByPage(sendableReportVisuals);

  const pageNameToPageTitleMap = {};
  Object.values(groupedVisualsByPage).forEach(visuals => {
    visuals.forEach(visual => {
      const pageName = visual.page.name;
      const pageTitle = visual.page.displayName;
      pageNameToPageTitleMap[pageName] = pageTitle;
    });
  });

  // Render function for visual items
  const renderVisualItem = (visuals, Component) => (
    <Box mb={2.5} key={visuals.map(v => v.name).join('')}>
      <Typography fontSize={17} ml={1.5} mb={1} variant="h6" color="primary" fontFamily="SoehneBreitKraftig">
        {pageNameToPageTitleMap[visuals[0].page.name]}
      </Typography>
      {visuals.map(visual => (
        <Component
          key={visual.name}
          disabled={isLoadingDataFromVisual}
          sx={{
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main}`,
            mb: 1,
            mx: 1,
            pr: 0
          }}
          onClick={() => onSelect(visual)}
        >
          <ListItemText primary={visual.title} />
          <ListItemIcon sx={{minWidth: 0, color: theme.palette.primary.main, mr: 0}}>
            <ArrowForwardIosOutlinedIcon fontSize="small" />
          </ListItemIcon>
        </Component>
      ))}
    </Box>
  );

  return (
    <Box>
      {isExtraSmallScreen ? (
        <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
          <AppBar sx={{position: 'sticky', top: 0}}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ml: 2, flex: 1, color: '#FFF'}} variant="h6" color="white">
                Partager des données
              </Typography>
            </Toolbar>
          </AppBar>
          <List>{Object.entries(groupedVisualsByPage).map(([_, visuals]) => renderVisualItem(visuals, ListItemButton))}</List>
        </Dialog>
      ) : (
        <Menu
          sx={{maxHeight: 450, marginBottom: 24, px: 1.5, borderRadius: 8}}
          open={isOpen}
          onClose={onClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {Object.entries(groupedVisualsByPage).map(([_, visuals]) => renderVisualItem(visuals, MenuItem))}
        </Menu>
      )}
    </Box>
  );
};

ChatbotShareDataMenu.defaultProps = {
  anchorEl: null
};

ChatbotShareDataMenu.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  anchorEl: oneOfType([object, element, node, oneOf([null])])
};

export default ChatbotShareDataMenu;
