import {MAXIMUM_NUMBER_OF_CUSTOM_REPORTS} from '../../../const';
import useProfile from '../../../hooks/providers/useProfile';
import useReport from '../../../hooks/providers/useReport';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import CreateReportOrWorkspaceButton from '../CreateReportOrWorkspaceButton';

const CreateReportButton = () => {
  const {isDefaultWorkspace, isUserInWorkspace, selectedWorkspace, workspaces, setCreateReportModalOpen, setIsCustomReportCreation, setAnchorElReportTabsMenu} = useWorkspaces();
  const {reports} = useReport();
  const {profile} = useProfile();

  const numberOfNoticesReport = workspaces.length - 1; // Each workspace contains a Notice report (except default: DeFi)
  const numberOfReportsCreatedByUser = (reports?.filter(r => r.isEditable) || []).length - numberOfNoticesReport;
  const maximumNumberOfReportsReached = numberOfReportsCreatedByUser > MAXIMUM_NUMBER_OF_CUSTOM_REPORTS;

  const handleClick = () => {
    if (maximumNumberOfReportsReached) return;
    setCreateReportModalOpen(true);
    setIsCustomReportCreation(false);
    setAnchorElReportTabsMenu(null);
  };

  const isUserPartOfCurrentWorkspace = isUserInWorkspace(profile?.id, selectedWorkspace);

  const getTooltipMessage = () => {
    if (!isUserPartOfCurrentWorkspace) {
      return "Seuls les utilisateurs d'un espace de travail peuvent créer un rapport au sein de cet espace.";
    }
    return 'Vous avez atteint le nombre maximum de rapports. Vous devrez en supprimer un pour pouvoir en créer de nouveau.';
  };

  const isButtonDisabled = isDefaultWorkspace || !isUserPartOfCurrentWorkspace;
  const tooltipMessage = getTooltipMessage();

  return <CreateReportOrWorkspaceButton disabled={isButtonDisabled} maxLimitReached={maximumNumberOfReportsReached} onClick={handleClick} tooltipTitle={tooltipMessage} label="Ajouter un rapport" />;
};

export default CreateReportButton;
