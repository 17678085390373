import {useEffect, useState} from 'react';

import {POWER_BI_PAGES, POWER_BI_REPORT_BOOKMARKS} from '../../../../const';
import useProjects from '../../../../hooks/providers/useProjects';
import useReport from '../../../../hooks/providers/useReport';
import {getAccountantExpectedElementsVisualJsonData} from '../../../../utils';
import Button from '../../../form/buttons/Button';
import AccountantDocumentRequestModal from '../../AccountantDocumentRequestModal/AccountantDocumentRequestModal';

const AccountantDocumentRequestButton = () => {
  const [documentRequestBookmarkApplied, setDocumentRequestBookmarkApplied] = useState(false);
  const [isAccountantDocumentRequestModalOpen, setAccountantDocumentRequestModalOpen] = useState(false);
  const [isDataArrayEmpty, setIsDataArrayEmpty] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [lastBookmarkExceptFilters, setLastBookmarkExceptFilters] = useState(null);

  const {reportSelectedPage, currentBookmark, selectedReport} = useReport();
  const {isGuestMode} = useProjects();

  const isSynthesisPage = reportSelectedPage.name === POWER_BI_PAGES.reportSynthesis.id;
  const shouldDisplay = documentRequestBookmarkApplied && isSynthesisPage && !isGuestMode;

  useEffect(() => {
    (async () => {
      const b = await selectedReport.bookmarksManager.getBookmarks();
      setBookmarks(b);
    })();
  }, []);

  useEffect(() => {
    const checkVisualData = async () => {
      if (!shouldDisplay) return;
      const data = await getAccountantExpectedElementsVisualJsonData(reportSelectedPage);
      setIsDataArrayEmpty(data.length === 0);
    };
    checkVisualData();

    const intervalId = setInterval(checkVisualData, 1000);
    return () => clearInterval(intervalId);
  }, [shouldDisplay, reportSelectedPage]);

  useEffect(() => {
    const isDocumentRequestBookmark = lastBookmarkExceptFilters === POWER_BI_REPORT_BOOKMARKS.accountantExpectedElements.name;
    if (isDocumentRequestBookmark) {
      setDocumentRequestBookmarkApplied(true);
    } else {
      setDocumentRequestBookmarkApplied(false);
    }
  }, [lastBookmarkExceptFilters]);

  useEffect(() => {
    const openOrCloseFiltersBookmarks = bookmarks.find(b => b.name === POWER_BI_REPORT_BOOKMARKS.openOrCloseFilters.name)?.children;
    const isAnOpenOrCloseFiltersBookmark = openOrCloseFiltersBookmarks?.find(b => b.name === currentBookmark) !== undefined;
    const isTreasuryBookmark = currentBookmark === POWER_BI_REPORT_BOOKMARKS.treasuryDefiGestion.name;
    const isProjectChoiceBookmark = currentBookmark === POWER_BI_REPORT_BOOKMARKS.projectChoiceDefiGestion.name;

    if (isSynthesisPage && !isAnOpenOrCloseFiltersBookmark && !isTreasuryBookmark && !isProjectChoiceBookmark) {
      setLastBookmarkExceptFilters(currentBookmark);
    }
  }, [currentBookmark]);

  if (shouldDisplay) {
    return (
      <>
        <Button disabled={isDataArrayEmpty} variant="contained" color="secondary" onClick={() => setAccountantDocumentRequestModalOpen(true)}>
          Envoyer une demande de documents
        </Button>
        <AccountantDocumentRequestModal isOpen={isAccountantDocumentRequestModalOpen} closeModal={() => setAccountantDocumentRequestModalOpen(false)} />
      </>
    );
  }

  return null;
};

export default AccountantDocumentRequestButton;
