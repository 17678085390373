import {useState, useEffect} from 'react';

import {func, oneOfType, node, bool} from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import WidgetsContainer from 'components/widgets/WidgetsContainer';
import useProfile from 'hooks/providers/useProfile';

import TranscriptWorkingDayModal from './components/audio-transcript/TranscriptWorkingDayModal/TranscriptWorkingDayModal';
import ExpiredSubscriptionBanner from './components/banner/ExpiredSubscriptionBanner';
import ProjectsLimitReachedBanner from './components/banner/ProjectsLimitReachedBanner';
import TrialBanner from './components/banner/TrialBanner';
import ChatbotSidebar from './components/chatbot/ChatbotSidebar';
import ContactFormBackdrop from './components/contact/ContactFormBackdrop';
import Header from './components/header/Header';
import CustomSnackbar from './components/snackbar/Snackbar';
import Spinner from './components/spinner/Spinner';
import UploadDialog from './components/upload/UploadDialog';
import useAuth from './hooks/providers/useAuth';
import useLoading from './hooks/providers/useLoading';
import usePayment from './hooks/providers/usePayment';
import useProjects from './hooks/providers/useProjects';
import useReport from './hooks/providers/useReport';
import useSnackbar from './hooks/providers/useSnackbar';
import useUpload from './hooks/providers/useUpload';
import useWorkspaces from './hooks/providers/useWorkspaces';
import Report from './pages/Report';
import {makeHumanFriendlyUrl} from './utils/data-formatting';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 64px);
  width: 100%;
`;

const Layout = ({children, shouldWaitForReportsLoading = true}) => {
  const [reportsLoading, setReportsLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const {loadReports, setSelectedReport} = useReport();
  const {isRegeneratingToken, user, isNewUser} = useAuth();
  const {profile, isConsentsLoading} = useProfile();
  const uploadContext = useUpload();
  const paymentContext = usePayment();
  const snackbarContext = useSnackbar();
  const {pennylaneDataIntegrationInProgress, getPennylaneDataIntegrationJobStatus} = useProjects();
  const {isUploadFilesModalOpen, setIsUploadFilesModalOpen} = uploadContext;
  const {setSelectedProject, projects} = useProjects();
  const {getHelpButtons} = useProfile();
  const {setCompletedApiCalls} = useLoading();
  const {getWorkspaces} = useWorkspaces();

  const fetchSubscriptionsAndReports = async (shouldFetchWorkspaces = false) => {
    if (user?.tokenAad) {
      paymentContext.setIsSubscriptionLoading(true);
      setReportsLoading(true);

      const promises = [paymentContext.getUserSubscriptions(), loadReports()];
      if (shouldFetchWorkspaces) {
        promises.push(getWorkspaces());
      }
      await Promise.all(promises);

      setCompletedApiCalls(prevCompleted => prevCompleted + 2);
      paymentContext.setIsSubscriptionLoading(false);
      setReportsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!isNewUser) {
        await fetchSubscriptionsAndReports();
      }
    })();
  }, [user?.tokenAad]);

  useEffect(() => {
    (async () => {
      if (isNewUser && projects) {
        await fetchSubscriptionsAndReports(true);
      }
    })();
  }, [user?.tokenAad, projects]);

  useEffect(() => {
    if (!location.pathname.startsWith('/reports')) {
      setSelectedReport(null);
    }
  }, [location.pathname]);

  // This hooks gets job status concerning an ongoing Pennylane data integration
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (pennylaneDataIntegrationInProgress) {
      getPennylaneDataIntegrationJobStatus();

      const intervalId = setInterval(() => {
        getPennylaneDataIntegrationJobStatus();
      }, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [pennylaneDataIntegrationInProgress]);

  // This hooks aims to redirect user to the onboarding document
  // Navigates new users to //base-documentaire/livret-de-bienvenue
  useEffect(() => {
    if (getHelpButtons && profile?.newUser) {
      const categories = Object.keys(getHelpButtons);
      for (let i = 0; i < categories.length; i++) {
        const categoryKey = categories[i];
        const category = getHelpButtons[categoryKey];
        const welcomeDoc = category?.find(doc => doc.is_welcome_doc);
        if (welcomeDoc && welcomeDoc.subtitle && !localStorage.getItem('welcomedocopened')) {
          localStorage.setItem('welcomedocopened', true);
          const formattedGroupName = makeHumanFriendlyUrl(categoryKey);
          const formattedDocumentName = makeHumanFriendlyUrl(welcomeDoc.subtitle);
          navigate(`/${formattedGroupName}/${formattedDocumentName}`);
          break;
        }
      }
    }
  }, [getHelpButtons, navigate, profile]);

  // Forces report container to be on a single page without scroll
  const fullHeightContainerStyle = {
    maxHeight: '100vh',
    overflow: 'hidden'
  };

  // !isUploadFilesModalOpen because this style breaks react-dropzone.
  const containerStyle = location?.pathname?.startsWith('/reports') && !isUploadFilesModalOpen ? fullHeightContainerStyle : {};

  return (
    <div style={containerStyle}>
      <CustomSnackbar
        hasSpinner={snackbarContext.hasSpinner}
        text={snackbarContext.message}
        isOpen={snackbarContext.isOpen}
        severity={snackbarContext.severity}
        duration={snackbarContext.duration}
        onClose={snackbarContext.closeSnackbar}
      />
      <UploadDialog
        isOpen={isUploadFilesModalOpen}
        closeModal={() => {
          setIsUploadFilesModalOpen(false);
          setSelectedProject(null);
        }}
      />

      <Header />

      <ChatbotSidebar>
        {isConsentsLoading || (reportsLoading && shouldWaitForReportsLoading) || isRegeneratingToken ? (
          <SpinnerContainer>
            <Spinner withoutText size={128} isLoading />
          </SpinnerContainer>
        ) : (
          <>
            <ExpiredSubscriptionBanner />
            <ProjectsLimitReachedBanner />
            <TrialBanner />
            <>
              {children}
              <WidgetsContainer />
              <Report />
            </>
          </>
        )}
      </ChatbotSidebar>
      <TranscriptWorkingDayModal />
      <ContactFormBackdrop />
    </div>
  );
};
Layout.defaultProps = {
  shouldWaitForReportsLoading: true,
  children: null
};

Layout.propTypes = {
  children: oneOfType([node, func]),
  // eslint-disable-next-line react/boolean-prop-naming
  shouldWaitForReportsLoading: bool
};

export default Layout;
