import {useEffect, useState} from 'react';

import {EventType} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS} from '../const';
import useHttp from '../hooks/misc/useHttp';
import useAuth from '../hooks/providers/useAuth';
import useError from '../hooks/providers/useError';
import useLoading from '../hooks/providers/useLoading';
import Maintenance from '../pages/Maintenance';

const RequireMaintenanceStatus = ({children}) => {
  const [loading, setLoading] = useState(false);
  const [maintenanceData, setMaintenanceData] = useState(null);
  const {_get} = useHttp();

  const auth = useAuth();
  const context = useMsal();
  const {setCompletedApiCalls} = useLoading();
  const {triggerError} = useError();
  const {instance} = context;

  useEffect(() => {
    const eventCallback = instance.addEventCallback(async message => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const {payload} = message;
        auth.setUser({
          ...(auth.user || {}),
          username: payload.account.name,
          tokenAad: payload.accessToken,
          mail: payload.account.username
        });
      }
    });
    return () => {
      if (eventCallback) {
        instance.removeEventCallback(eventCallback);
      }
    };
  }, [context.instance]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const url = API_ENDPOINTS.miscellaneous.maintenance;
        const {response, responseJson: data} = await _get(url);

        if (response.ok) {
          setCompletedApiCalls(prevCompleted => prevCompleted + 1);
          setMaintenanceData(data);
        } else {
          // eslint-disable-next-line no-console
          console.error({response});
          if (response.status >= 500) {
            triggerError('Unexpected error');
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error({e});
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return null;
  }

  return maintenanceData?.maintenance_mode && !loading ? <Maintenance title={maintenanceData.title} subtitle={maintenanceData.subtitle} /> : children;
};

RequireMaintenanceStatus.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default RequireMaintenanceStatus;
