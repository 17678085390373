/* eslint-disable consistent-return */
import {useEffect, useMemo, useState} from 'react';

import {func, node, oneOfType} from 'prop-types';

import {API_ENDPOINTS, DEFAULT_PROJECTS_SCHEMAS, POWER_BI_PAGES, SNACKBAR_ACTIONS, SUMMARY_THEMES} from '../const';
import ReportSummaryContext from '../contexts/ReportSummaryContext';
import useHttp from '../hooks/misc/useHttp';
import useAuth from '../hooks/providers/useAuth';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import {formatJsonSummaryAccountingDataToCsv, getSelectedTabInChargesPage, groupEntriesAmountsByDate} from '../utils';

const ReportSummaryProvider = ({children}) => {
  const [summaryTheme, setSummaryTheme] = useState('');
  const [summaryHasBeenUpdated, setSummaryHasBeenUpdated] = useState(false);
  const [reportAudios, setReportAudios] = useState([]);
  const [isSummaryInstructionsModalOpen, setIsSummaryInstructionsModalOpen] = useState(false);
  const [shouldCustomizeInstructions, setShouldCustomizeInstructions] = useState(false);
  const [shouldShareAnonymizedData, setShouldShareAnonymizedData] = useState(true);
  const [promptModels, setPromptModels] = useState([]);
  const [chosenModel, setChosenModel] = useState(null);
  const [instructions, setInstructions] = useState('');
  const [promptModel, setPromptModel] = useState('');
  const [isSummaryShareDataModalOpen, setIsSummaryShareDataModalOpen] = useState(false);
  const [summariesData, setSummariesData] = useState([]);
  const [streamedSummary, setStreamedSummary] = useState([]);
  const [summariesLoading, setSummariesLoading] = useState(false);

  const [dataToShare, setDataToShare] = useState([]);

  const {_post, _get} = useHttp();
  const {showSnackbar} = useSnackbar();
  const {selectedReport} = useReport();
  const auth = useAuth();

  useEffect(() => {
    const themeData = summariesData.filter(entry => entry.Theme === summaryTheme);
    setDataToShare(themeData);
  }, [summaryTheme, summariesData]);

  const updateSummary = async ({newSummary, theme, siren, withAudio}) => {
    const url = API_ENDPOINTS.reports.summary.update;
    setSummaryHasBeenUpdated(false);
    try {
      const snackbarAction = withAudio ? SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY_WITH_AUDIO : SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY;
      showSnackbar(snackbarAction, {
        severity: 'warning',
        autoHide: false,
        hasSpinner: true
      });

      const {response} = await _post(url, {
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        theme,
        summary: newSummary,
        with_audio: withAudio
      });

      if (response.status === 200) {
        setSummaryHasBeenUpdated(true);
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY_SUCCESS);
        selectedReport.refresh();
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const getSummaryPromptModels = async () => {
    const url = API_ENDPOINTS.reports.summary.getModels;
    try {
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        setPromptModels(data);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const getSummariesData = async siren => {
    const url = API_ENDPOINTS.reports.summary.getData;
    setSummariesLoading(true);
    try {
      const {response, responseJson: data} = await _post(url, {
        siren,
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion
      });

      if (response.status === 200) {
        setSummariesData(data);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    } finally {
      setSummariesLoading(false);
    }
  };

  const savePromptModel = async (prompt, title) => {
    const url = API_ENDPOINTS.reports.summary.saveModel;
    try {
      const newPrompt = {
        title,
        prompt
      };

      const {response} = await _post(url, newPrompt);

      if (response.status === 200) {
        setPromptModels(currentPrompts => [...currentPrompts, newPrompt]);
        showSnackbar(SNACKBAR_ACTIONS.CREATE_PROMPT_MODEL_SUCCESS);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const deletePromptModel = async title => {
    const url = API_ENDPOINTS.reports.summary.deleteModel;
    try {
      const {response} = await _post(url, {
        title
      });

      if (response.status === 200) {
        setPromptModels(currentPrompts => [...currentPrompts].filter(p => p.title !== title));
        showSnackbar(SNACKBAR_ACTIONS.DELETE_PROMPT_MODEL_SUCCESS);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const getSummaryTheme = async report => {
    const currentPage = await report.getActivePage();
    let theme;

    if (currentPage.name === POWER_BI_PAGES.reportHome.id) {
      theme = SUMMARY_THEMES.ca;
    }
    if (currentPage.name === POWER_BI_PAGES.reportCharges.id) {
      theme = await getSelectedTabInChargesPage(currentPage);
    }
    return theme;
  };

  const getReportThemeAudio = async ({theme, siren}) => {
    const url = API_ENDPOINTS.reports.getReportThemeAudio;
    try {
      const {response, responseJson: data} = await _post(url, {
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        theme
      });

      if (response.status === 200) {
        const newAudio = {data, theme, siren};
        setReportAudios(currentAudios => {
          const newReportAudios = [...currentAudios];
          const existingAudioIndex = newReportAudios.findIndex(a => a.siren === siren && a.theme === theme);
          if (existingAudioIndex !== -1) {
            newReportAudios.splice(existingAudioIndex, 1);
          }
          newReportAudios.push(newAudio);
          return newReportAudios;
        });
      }

      return {data, success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const handleSSEMessage = dataString => {
    setStreamedSummary(currentWords => {
      return [...currentWords, dataString];
    });
  };

  const anonymizeData = data => {
    if (shouldShareAnonymizedData) {
      const groupedData = groupEntriesAmountsByDate(data);
      return groupedData.map(entry => {
        const {Compte, Catégorie, ...rest} = entry;
        return rest;
      });
    }
    return data;
  };

  const generateSummary = async (data, summaryInstructions = null) => {
    let formattedData = [...data];

    try {
      const url = API_ENDPOINTS.reports.summary.generateSummary;
      setStreamedSummary([]);

      // Format data as needed : anonymize if user picked anonymized data + format to csv
      if (shouldShareAnonymizedData) {
        formattedData = anonymizeData(data);
      }

      formattedData = formatJsonSummaryAccountingDataToCsv(formattedData);
      const dataWithoutQuotes = formattedData.replace(/"/g, '');
      const formattedBody = {
        data: dataWithoutQuotes,
        ...(summaryInstructions ? {instructions: summaryInstructions} : {})
      };

      return fetch(url, {
        method: 'POST',
        headers: {
          policy: process.env.REACT_APP_COMPANY_POLICY,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.tokenAad}`
        },
        body: JSON.stringify(formattedBody)
      })
        .then(async res => {
          if (res.body && res.body.pipeTo) {
            // eslint-disable-next-line no-undef
            const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
            const readStream = async () => {
              let streamingInProgress = true;
              while (streamingInProgress) {
                // eslint-disable-next-line no-await-in-loop
                const {value, done} = await reader.read();
                if (done) {
                  streamingInProgress = false;
                  break;
                }

                const finalValue = value?.replace(/\\n\\n/g, '\n\n')?.replace(/\\n/g, '\n');
                handleSSEMessage(finalValue);
              }
            };
            await readStream();

            return {
              success: true
            };
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error({error});
          return {
            success: true
          };
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message
      };
    }
  };

  const value = useMemo(
    () => ({
      summaryTheme,
      updateSummary,
      summaryHasBeenUpdated,
      getSummaryTheme,
      setSummaryTheme,
      getReportThemeAudio,
      reportAudios,
      setReportAudios,
      isSummaryInstructionsModalOpen,
      setIsSummaryInstructionsModalOpen,
      shouldCustomizeInstructions,
      setShouldCustomizeInstructions,
      getSummaryPromptModels,
      promptModels,
      savePromptModel,
      chosenModel,
      setChosenModel,
      instructions,
      setInstructions,
      promptModel,
      setPromptModel,
      deletePromptModel,
      shouldShareAnonymizedData,
      setShouldShareAnonymizedData,
      isSummaryShareDataModalOpen,
      setIsSummaryShareDataModalOpen,
      getSummariesData,
      summariesData,
      dataToShare,
      setDataToShare,
      generateSummary,
      streamedSummary,
      setSummariesData,
      summariesLoading
    }),
    [
      summaryTheme,
      getReportThemeAudio,
      summaryHasBeenUpdated,
      setSummaryTheme,
      getSummaryTheme,
      reportAudios,
      setReportAudios,
      isSummaryInstructionsModalOpen,
      setIsSummaryInstructionsModalOpen,
      shouldCustomizeInstructions,
      setShouldCustomizeInstructions,
      getSummaryPromptModels,
      promptModels,
      savePromptModel,
      chosenModel,
      setChosenModel,
      instructions,
      setInstructions,
      promptModel,
      setPromptModel,
      deletePromptModel,
      shouldShareAnonymizedData,
      setShouldShareAnonymizedData,
      isSummaryShareDataModalOpen,
      setIsSummaryShareDataModalOpen,
      getSummariesData,
      summariesData,
      dataToShare,
      setDataToShare,
      generateSummary,
      streamedSummary,
      setSummariesData,
      summariesLoading
    ]
  );

  return <ReportSummaryContext.Provider value={value}>{children}</ReportSummaryContext.Provider>;
};

ReportSummaryProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ReportSummaryProvider;
