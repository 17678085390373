const SEND_DOCUMENT_REQUEST_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Date pièce', className: 'header-cell'},
    {type: 'header', text: 'Journal', className: 'header-cell'},
    {type: 'header', text: 'Libellé', className: 'header-cell'},
    {type: 'header', text: 'Montant', className: 'header-cell'},
    {type: 'header', text: ' ', className: 'header-cell'}
  ]
};

const SEND_DOCUMENT_REQUEST_COLUMNS_NAMES = {
  date: 'date',
  journal: 'journal',
  amount: 'amount',
  label: 'label',
  delete: 'delete'
};

const getDocumentRequestColumns = dialogWidth => {
  const defaultColumnWidth = 150;
  const deleteColumnWidth = 50;
  const widthAlreadyTaken = defaultColumnWidth * 3 + deleteColumnWidth;
  const widthLeft = dialogWidth - widthAlreadyTaken;

  return [
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.date,
      width: defaultColumnWidth
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.journal,
      width: defaultColumnWidth
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.label,
      width: widthLeft < 150 ? 150 : widthLeft
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.amount,
      width: defaultColumnWidth
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.delete,
      width: deleteColumnWidth
    }
  ];
};

export {SEND_DOCUMENT_REQUEST_HEADER_ROW, getDocumentRequestColumns};
