import {useEffect, useRef, useState} from 'react';

import {TextField, DialogContent, DialogActions, Grid} from '@mui/material';
import {func, bool} from 'prop-types';
import 'dayjs/locale/fr';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import useReport from '../../../hooks/providers/useReport';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button';
import Checkbox from '../../form/Checkbox';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import PromptInstructionsBlock from './PromptInstructionsBlock';
import SharedDataBlock from './SharedDataBlock';
import SummaryPromptInstructionsModal from './SummaryPromptInstructionsModal';
import SummarySharedDataModal from './SummarySharedDataModal';

const UpdateReportSummaryModal = ({isOpen, closeModal}) => {
  const {selectedReport, selectedProject: project} = useReport();
  const {shouldCustomizeInstructions, streamedSummary, generateSummary, updateSummary, getSummaryTheme, getSummariesData, setSummariesData, summariesData, dataToShare, instructions} =
    useReportSummary();
  const {updateSummaryInProjects} = useProjects();

  const [summary, setSummary] = useState('');
  const [summaryBeforeUpdate, setSummaryBeforeUpdate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldGenerateAudio, setShouldGenerateAudio] = useState(true);
  const [isGeneratingAnalysis, setIsGeneratingAnalysis] = useState(false);
  const [shouldReplaceExistingSummary, setShouldReplaceExistingSummary] = useState(true);
  const textareaRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      const textarea = textareaRef.current?.querySelector('textarea');
      if (textarea) {
        textarea.scrollTop = textarea.scrollHeight;
      }
    };

    const timeoutId = setTimeout(scrollToBottom, 0);
    return () => clearTimeout(timeoutId);
  }, [streamedSummary]);

  const setDefaultSummaryText = async () => {
    const theme = await getSummaryTheme(selectedReport);
    const summaryBeforeUpdating = (project.summaries || []).find(s => s.themes === theme)?.summaries;
    setSummary(summaryBeforeUpdating || '');
    setSummaryBeforeUpdate(summaryBeforeUpdating || '');
  };

  const resetValues = async () => {
    await setDefaultSummaryText();
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const theme = await getSummaryTheme(selectedReport);
    closeModal();
    const res = await updateSummary({
      siren: project.siren,
      newSummary: summary,
      theme,
      withAudio: shouldGenerateAudio
    });

    if (res.success) {
      updateSummaryInProjects({siren: project.siren, newSummary: summary, theme});
    }
    setIsSubmitting(false);
  };

  const generateAnalysis = async () => {
    setIsGeneratingAnalysis(true);
    setSummaryBeforeUpdate(summary);
    if (shouldCustomizeInstructions) {
      await generateSummary(dataToShare, instructions);
    } else {
      await generateSummary(dataToShare);
    }
    setIsGeneratingAnalysis(false);
  };

  useEffect(() => {
    (async () => {
      await resetValues();
    })();
  }, [isOpen]);

  useEffect(() => {
    (async () => {
      if (summariesData.length === 0 && isOpen) {
        await getSummariesData(project.siren);
      }
    })();
  }, [isOpen, project.siren]);

  useEffect(() => {
    (async () => {
      setSummariesData([]);
    })();
  }, [project.siren]);

  useEffect(() => {
    (async () => {
      if (shouldReplaceExistingSummary && streamedSummary.length > 0) {
        setSummary(streamedSummary.join(''));
      } else {
        setSummary(`${summaryBeforeUpdate} ${streamedSummary.join('')}`);
      }
    })();
  }, [streamedSummary]);

  const minimumNumberOrChars = 300;
  const maximumNumberOrChars = 2000;
  const hasSummaryTextError = summary !== '' && !REGULAR_EXPRESSIONS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars).test(summary);
  const submitButtonDisabled = hasSummaryTextError || !summary || isSubmitting;

  return (
    <BaseModal maxWidth="md" canCloseOnBackdropClick={false} onClose={closeModal} open={isOpen} title="Modifier l'analyse" hasCloseIcon>
      <DialogContent>
        <Grid container mt={2} mb={3} flexDirection="column">
          <PromptInstructionsBlock />

          <SharedDataBlock />

          {isGeneratingAnalysis && streamedSummary.length === 0 ? <Spinner isLoading text="Génération de l'analyse" /> : null}

          <Grid item sx={{margin: '0 auto', mt: 1.5}}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Checkbox label="Remplacer l'analyse existante" checked={shouldReplaceExistingSummary} onChange={e => setShouldReplaceExistingSummary(e.target.checked)} />
              </Grid>

              <Grid item>
                <Button disabled={isGeneratingAnalysis} onClick={generateAnalysis} size="large" variant="contained" color="secondary">
                  Générer l'analyse
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <TextField
          ref={textareaRef}
          autoFocus
          multiline
          rows={10}
          sx={{mb: 2}}
          required
          value={summary}
          onChange={event => {
            setSummary(event.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
          label="Analyse"
          placeholder="Ceci est une analyse de rapport ..."
          color="primary"
          fullWidth
          error={hasSummaryTextError}
          helperText={hasSummaryTextError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars) : ''}
        />

        <Checkbox label="Générer l'audio de l'analyse" checked={shouldGenerateAudio} onChange={e => setShouldGenerateAudio(e.target.checked)} />
      </DialogContent>

      <DialogActions sx={{justifyContent: 'center'}}>
        <Button size="large" variant="contained" color="secondary" disabled={submitButtonDisabled} onClick={handleSubmit}>
          Mettre à jour
        </Button>
      </DialogActions>

      <SummaryPromptInstructionsModal />
      <SummarySharedDataModal />
    </BaseModal>
  );
};

UpdateReportSummaryModal.defaultProps = {
  isOpen: false
};

UpdateReportSummaryModal.propTypes = {
  isOpen: bool,
  closeModal: func.isRequired
};

export default UpdateReportSummaryModal;
